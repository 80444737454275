
// Helper functions as they relate to fact types
// The idea of this class is to encapsulate as much fact type customization as possible
// It's duplicated in main_old.js right now because that file is trash
// You also have to add to the input dropdown in index.html
export default class FactType {
    constructor() { }

    static intToFactType(factType) {
        switch (parseInt(factType)) {
            case 0:
                return "Cat";
            case 1:
                return "Dog";
            case 2:
                return "Sloth";
            case 3:
                return "Xmas";
            case 4:
                return "NicCage";
            case 5:
                return "Trump";
            case 6:
                return "Biden";
            default:
                return "Cat";
        }
    }
}