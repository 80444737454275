import { enforceFormat, formatToPhone } from "./phone_format";
import RegRequest from "./requests/reg-request";
import Analytics from "./analytics";
import CurrentUser from "./current_user";
import UserFlags from "./user-flags";
import SentryWrapper from "./sentry-wrapper";

const currentUser = new CurrentUser();
const userFlags = new UserFlags();

const modalInnerHtml = `
<div id="reg-modal">
<div class="reg-modal-container">
  <div class="title-container">
    <div class="text-container">
      <h1 id="title-header"></h1>
      <p id="title-subheader">
      </p>
    </div>
  </div>
  <div class="slider">
    <div class="line"></div>
    <div class="subline inc"></div>
    <div class="subline dec"></div>
  </div>
  <div class="sign-up-container">
    <form id="reg-form" action="#">
      <p id="reg-error-message-container">
        <span data-icon="ei-exclamation"></span>
        <span id="reg-error-message"></span>
      </p>
      <label>Email</label>
      <input
        id="reg-email-input"
        type="email"
        required
        placeholder="ex. johnny.appleseed@gmail.com"
      />
      <label>Password</label>
      <input
        id="reg-password-input"
        type="password"
        required
        minlength="4"
        placeholder=""
      />
      <div id="reg-phone-input-container" class="hide">
          <label>Phone Number (optional)</label>
          <input
          id="reg-phone-input"
          type="text"
          placeholder="ex. (860) 867-5309"
          pattern="^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$"
          />
          <small class="help-text">Used to notify for target replies</small>
      </div>

      <!-- <div class="checkbox-form">
              <div class="checkbox-container">
                  <input type="checkbox" id="sms-notif" name="sms-notif" checked>
                  <label for="sms-notif">Send me a text when my target replies</label><br>
              </div>
              <div class="checkbox-container">
                  <input type="checkbox" id="email-notif" name="email-notif" checked>
                  <label for="email-notif">Send me an email when my target replies</label>
              </div>
          </div> -->
      
      <div id="login-btn-container" class="hide">
          <button id="login-btn" type="button" class="form-cta">
              Login
          </button>
          <p id="login-close-button" class="dismiss-btn">Close</p>
      </div>
      <div id="sign-up-btn-container" class="hide">
          <button id="sign-up-btn" type="button" class="form-cta">
              Sign Up
          </button>
          <p id="reg-close-button" class="dismiss-btn">Maybe later</p>
      </div>
    </form>
  </div>
</div>
</div>
`;

/**
 * You must have a <div> at the bottom of your page with the follow id
 * `reg-modal-parent-container`
 */
export function showModal(showInCreateAccountMode) {
  const regModal = document.getElementById("reg-modal");
  regModal.classList.add("reg-modal-show");

  const signUpBtnContainer = document.getElementById("sign-up-btn-container");
  const loginBtnContainer = document.getElementById("login-btn-container");

  const titleHeader = document.getElementById("title-header");
  const titleSubheader = document.getElementById("title-subheader");

  const phoneInputContainer = document.getElementById(
    "reg-phone-input-container"
  );

  if (showInCreateAccountMode) {
    // Everything is "hide" by default

    titleHeader.innerHTML = "Create Account";
    titleSubheader.innerHTML =
      "Access your account across all your devices and get notified when your targets reply.";

    signUpBtnContainer.classList.remove("hide");
    signUpBtnContainer.classList.add("show");

    phoneInputContainer.classList.remove("hide");
    phoneInputContainer.classList.add("show");
  } else {
    titleHeader.innerHTML = "Login";
    titleSubheader.innerHTML =
      "Already have an account, login below!<br />To create an account, add your first contact";

    loginBtnContainer.classList.remove("hide");
    loginBtnContainer.classList.add("show");
  }
}

export function hideModal() {
  const regModal = document.getElementById("reg-modal");
  regModal.classList.remove("reg-modal-show");
  userFlags.setHasSeenRegModal();
}

// TODO: could add event listeners here too, to get notified of success

var domReadyCallback = function () {
  const regModalParentContainer = document.getElementById(
    "reg-modal-parent-container"
  );
  regModalParentContainer.innerHTML = modalInnerHtml;

  // Handler when the DOM is fully loaded
  const inputElement = document.getElementById("reg-phone-input");
  inputElement.addEventListener("keydown", enforceFormat);
  inputElement.addEventListener("keyup", formatToPhone);

  const regModal = document.getElementById("reg-modal");
  const regForm = document.getElementById("reg-form");

  const signUpButton = document.getElementById("sign-up-btn");
  const regCloseButton = document.getElementById("reg-close-button");

  const loginButton = document.getElementById("login-btn");
  const loginCloseButton = document.getElementById("login-close-button");

  const errorMessageSpan = document.getElementById("reg-error-message");
  const errorMessageContainer = document.getElementById(
    "reg-error-message-container"
  );

  setupCreateModal();
  setupLoginModal();

  function setupLoginModal() {
    loginCloseButton.addEventListener("click", () => {
      hideModal();
    });
    loginButton.addEventListener("click", () => {
      const emailInputValue = document.getElementById("reg-email-input").value;
      const passwordInputValue =
        document.getElementById("reg-password-input").value;

      Analytics.track("Login", "Start login", "Registration");

      // Disable buttons and show loader
      showLoading();
      // Hide loading state if one was showing (could be a retry)
      hideError();

      // --- Validate fields
      // Do basic HTML validation
      if (!regForm.checkValidity()) {
        if (regForm.reportValidity) {
          regForm.reportValidity();
        } else {
          alert("The input you entered was invalid");
        }

        hideLoading();
        Analytics.track("Login Validation Error", "Form", "Registration");
        return;
      }

      if (!emailInputValue || !passwordInputValue) {
        showErrorMessage("Please fill out all required fields");
        hideLoading();
        Analytics.track(
          "Login Validation Error",
          "Email/Password",
          "Registration"
        );
        return;
      }

      // Send request
      const submitData = {
        email: emailInputValue,
        password: passwordInputValue,
      };

      RegRequest.login(submitData)
        .then((response) => {
          // Repsonses with status < 400 get resolved (success!).
          // You can access response.status and response.data here
          hideLoading();

          currentUser.save(response.data);
          hideModal();
          Analytics.track("Login Success", "Form", "Registration");

          setTimeout(location.reload(), 300);
        })
        .catch((response) => {
          // repsonses with status >= 400 get rejected. you can access response.status and response.data here too
          if (
            response.data &&
            response.data.error_code !== null &&
            typeof response.data.error_code !== undefined
          ) {
            showErrorMessage(getLoginErrorMessage(response.data.error_code));
            hideLoading();
            Analytics.track(
              "Login Failure",
              response.data.error_code,
              "Registration"
            );
          } else {
            console.log(response.data);
            showErrorMessage("We couldn't login, please try again.");
            hideLoading();

            Analytics.trackJsError(response, "login_request");
          }
        });
    });
  }

  function setupCreateModal() {
    regCloseButton.addEventListener("click", () => {
      showCloseConfirmation();
    });
    signUpButton.addEventListener("click", () => {
      userFlags.setHasSeenRegModal();

      const emailInputValue = document.getElementById("reg-email-input").value;
      const passwordInputValue =
        document.getElementById("reg-password-input").value;
      const phoneInputValue = document.getElementById("reg-phone-input").value;
      const strippedPhoneValue = phoneInputValue.replace(/\D/g, "");

      Analytics.track(
        "Associate Account",
        strippedPhoneValue ? "Phone" : "No Phone",
        "Registration"
      );

      // Disable buttons and show loader
      showLoading();
      // Hide loading state if one was showing (could be a retry)
      hideError();

      // --- Validate fields
      // Do basic HTML validation
      if (!regForm.checkValidity()) {
        if (regForm.reportValidity) {
          regForm.reportValidity();
        } else {
          alert("The input you entered was invalid");
        }

        hideLoading();
        Analytics.track(
          "Associate Account Validation Error",
          "Form",
          "Registration"
        );
        return;
      }

      if (!emailInputValue || !passwordInputValue) {
        showErrorMessage("Please fill out all required fields");
        hideLoading();
        Analytics.track(
          "Associate Account Validation Error",
          "Email/Password",
          "Registration"
        );
        return;
      }

      // Send request
      const submitData = {
        email: emailInputValue,
        password: passwordInputValue,
      };
      if (strippedPhoneValue) {
        submitData.phone_number = "1" + strippedPhoneValue;
      }

      RegRequest.createAccount(submitData)
        .then((response) => {
          // Repsonses with status < 400 get resolved (success!).
          // You can access response.status and response.data here
          hideLoading();

          currentUser.save(response.data);
          hideModal();
          Analytics.track(
            "Associate Account Success",
            strippedPhoneValue ? "Phone" : "No Phone",
            "Registration"
          );

          setTimeout(location.reload(), 400);
        })
        .catch((response) => {
          // repsonses with status >= 400 get rejected. you can access response.status and response.data here too
          if (
            response.data &&
            response.data.error_code !== null &&
            typeof response.data.error_code !== undefined
          ) {
            showErrorMessage(getErrorMessage(response.data.error_code));
            hideLoading();
            Analytics.track(
              "Associate Account Failure",
              response.data.error_code,
              "Registration"
            );
          } else {
            console.log(response.data);
            showErrorMessage(
              "We couldn't create your account, please try again."
            );
            hideLoading();

            Analytics.trackJsError(response, "associate_request");
          }
        });
    });
  }

  function showLoading() {
    regModal.classList.add("reg-loading");
  }

  function hideLoading() {
    regModal.classList.remove("reg-loading");
  }

  function showErrorMessage(message) {
    errorMessageSpan.innerHTML = message;
    errorMessageContainer.classList.add("show-error");
  }

  function hideError() {
    errorMessageContainer.classList.remove("show-error");
    errorMessageSpan.innerHTML = "";
  }

  function showCloseConfirmation() {
    var r = confirm(
      "Without an account, you could lose access to your targets when you leave this page."
    );
    if (r == true) {
      // Close the modal
      hideModal();
    } else {
      // Leave the modal open
    }
  }

  function getLoginErrorMessage(errorCode) {
    const EMPTY_EMAIL = 1;
    const EMPTY_PASSWORD = 2;
    const NO_USER_FOUND = 3;

    switch (errorCode) {
      case EMPTY_EMAIL:
      case EMPTY_PASSWORD:
        return "Please fill out all required fields.";
      case NO_USER_FOUND:
        return "No account found with those credentials, please try again.";
    }
  }

  function getErrorMessage(errorCode) {
    const EMAIL_EXISTS = 0;
    const ACCOUNT_HAS_EMAIL_ALREADY = 1;
    const NO_ACCOUNT_TO_UPDATE = 2;
    const EMPTY_EMAIL = 3;
    const EMPTY_PASSWORD = 4;
    const UPDATE_USER_FAIL = 5;
    const INVALID_EMAIL = 6;
    const INVALID_PHONE_NUMBER = 7;

    switch (errorCode) {
      case EMAIL_EXISTS:
        return "Email is taken, please try a different one (or go to web.catfacts.co to login).";
      case ACCOUNT_HAS_EMAIL_ALREADY:
        return "You've already created an account. Please refresh the page.";
      case NO_ACCOUNT_TO_UPDATE:
        return "Please make a purchase before creating an account.";
      case EMPTY_EMAIL:
      case EMPTY_PASSWORD:
        return "Please fill out all required fields.";
      case UPDATE_USER_FAIL:
        return "Something went wrong, please try again.";
      case INVALID_EMAIL:
        return "The email you entered is invalid.";
      case INVALID_PHONE_NUMBER:
        return "The phone number you entered is invalid.";
    }
  }
};

if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  domReadyCallback();
} else {
  document.addEventListener("DOMContentLoaded", domReadyCallback);
}
