/**
 * Sentry doesn't work with some ad blockers, so we have to try/catch everything
 */
export default class SentryWrapper {
  static init() {
    try {
      const isProd = process.env.NODE_ENV == "production";
      // Start Sentry only if we're on prod
      Sentry.init({
        dsn: "https://3ee4ed1b11a0448296b5dba47adb94c0@sentry.io/1437357",
        environment: isProd ? "production" : "development",
        enabled: isProd,
      });
    } catch (e) {
      console.error("Sentry failed to initialize");
    }
  }
  static captureException(e) {
    try {
      Sentry.captureException(e);
    } catch (e) {
      console.error("Sentry failed to capture exception");
    }
  }
}
