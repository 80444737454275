import { errors } from "./errors";
import SentryWrapper from "./sentry-wrapper";

// Global Event Listener
document.addEventListener("click", (event) => {
  let el = event.target;
  if (el.nodeName === "INPUT") {
    return;
  }

  let trackEl = el.closest("[data-name]");

  if (!trackEl) {
    return;
  }

  gtag("event", trackEl.dataset.name, {
    event_category: "button_click",
    event_label: el.dataset.label,
  });
});

const TIER_MAP = {
  tier1: {
    name: "10 Facts",
    price: "2.00",
  },
  tier2: {
    name: "30 Facts",
    price: "5.00",
  },
  tier3: {
    name: "60 Facts",
    price: "12.00",
  },
};

export default class Analytics {
  static setUserId(uid) {
    gtag("set", { user_id: uid });
  }

  static track(action, label, category = "button_click", callback = null, timeout = 500) {
    let callbackTriggered = false;

    // Wrap the provided callback with our own to include the fallback logic
    const wrappedCallback = function() {
      if (!callbackTriggered) {
        callbackTriggered = true;
        if (callback && typeof callback === 'function') {
          callback();
        }
      }
    };

    gtag("event", action, {
      event_label: label,
      event_category: category,
      event_callback: wrappedCallback,
    });

    // Set up the fallback to invoke the callback after the specified timeout
    setTimeout(() => {
      if (!callbackTriggered) {
        console.log('Fallback callback triggered');
        wrappedCallback();
      }
    }, timeout);
  }

  static trackJsError(error, type) {
    try {
      Analytics.track(error.name, error.message, "js_error_" + type);
      SentryWrapper.captureException(JSON.stringify(error));
    } catch (e) {
      SentryWrapper.captureException(e);
    }
  }

  static trackError(error) {
    try {
      Analytics.track(
        errors.name(error.error_code),
        error.dev_msg,
        "form_error"
      );
      SentryWrapper.captureException(JSON.stringify(error));
    } catch (e) {
      SentryWrapper.captureException(e);
    }
  }

  static trackCompletedPurchase({ contact_tier, stripe_token }) {
    return new Promise((resolve) => {
      if (contact_tier === "tier_promo_code") {
        gtag("event", "n/a", {
          event_category: "redeem_promo_code",
        });

        resolve();
        return;
      }
      if (contact_tier === "tier_free_10") {
        gtag("event", "n/a", {
          event_category: "free_purchase",
        });

        resolve();
        return;
      }

      gtag("event", "successful_purchase_and_add");

      gtag_report_conversion();

      try {
        fbq("track", "Purchase", {
          currency: "USD",
          value: TIER_MAP[contact_tier].price,
        });

        gtag("event", "purchase", {
          transaction_id: stripe_token,
          value: TIER_MAP[contact_tier].price,
          currency: "USD",
          items: [
            {
              id: contact_tier,
              name: TIER_MAP[contact_tier].name,
              category: "facts",
              list_position: Object.keys(TIER_MAP).indexOf(contact_tier),
              quantity: 1,
              price: TIER_MAP[contact_tier].price,
            },
          ],
          event_callback() {
            resolve();
          },
        });
        // Wait one second then resolve (no-ops if already resolved)
        // This is if there's an ad blocker that prevents event_callback from firing
        setTimeout(() => {
          resolve();
        }, 1000);
      } catch (e) {
        SentryWrapper.captureException(e);
        resolve();
      }
    });
  }
}
