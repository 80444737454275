import Analytics from "./analytics";
import SentryWrapper from "./sentry-wrapper";

const generateUID = () => {
  return Math.random().toString(36).substr(2, 9);
};

const guestUser = {
  unallocated_msgs: 0,
  is_paid: 0,
  total_msgs: 0,
  _id: generateUID(),
};

export default class CurrentUser {
  constructor() {
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      this._isGuestUser = false;

      if (user) {
        this._user = user;
        // Set user id whenever the object is accessed (and a user exists)
        Analytics.setUserId(this.id);
      } else {
        this._user = guestUser;
        this._isGuestUser = true;
      }
    } catch (e) {
      this._user = guestUser;
      this._isGuestUser = true;
      SentryWrapper.captureException(e);
    }
  }

  save(userAttributes) {
    this._user = userAttributes;
    this._isGuestUser = false;

    try {
      // Set user id when the user is first saved
      Analytics.setUserId(this.id);
      localStorage.setItem("user", JSON.stringify(this._user));
    } catch (e) {
      // Failed to write to localStorage, not sure what to do here.
      // This means the user has browser preferences that forbid us from using it
      SentryWrapper.captureException(e);
    }
  }

  logout() {
    localStorage.removeItem("user");
  }

  get isPersisted() {
    return Boolean(this._user.created_on);
  }

  get isLoggedIn() {
    return this.isPersisted && this.hasEmailAddress;
  }

  get id() {
    return this._user._id;
  }

  // I think we need this because this is how we initially generate an ID
  // for new users.
  // But we want to ignore guest users once we're in a spot where you _should_ have an ID
  // (like the contacts page)
  get isGuestUser() {
    return this._isGuestUser;
  }

  get joinDate() {
    return this._user.created_on;
  }

  get totalMsgs() {
    return this._user.total_msgs;
  }

  get remainingMsgs() {
    return this._user.unallocated_msgs;
  }

  get isEligibleForFreePlan() {
    return this._user.unallocated_msgs >= 10;
  }

  get emailAddress() {
    return this._user.email;
  }

  get hasEmailAddress() {
    return (
      this._user.email != null &&
      this._user.email.length > 0 &&
      this._user.email != "NULL"
    );
  }
}
