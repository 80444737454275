import CurrentUser from './current_user';

const currentUser = new CurrentUser();

export default class Checkout {
  static BASE_URL = `${API_BASE_URL}/api_v0/add_contact.php`;

  // https://github.com/github/fetch/issues/203#issuecomment-331610451
  static processResponse(response) {
    return new Promise((resolve, reject) => {
      // will resolve or reject depending on status, will pass both "status" and "data" in either case
      let func;
      response.status < 400 ? func = resolve : func = reject;
      response.json().then(data => func({ 'status': response.status, 'data': data }));
    });
  }

  static submit(data) {
    const formData = new FormData();

    data.uid = currentUser.id;

    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })

    return fetch(Checkout.BASE_URL, {
      method: 'POST',
      body: formData
    }).then(this.processResponse)
  }
}
