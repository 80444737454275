const ERROR_CODES = [
  'EMPTY_VALUE',
  'CREATE_USER_FAIL',
  'INVALID_FACT_AMOUNT',
  'CREATE_PURCHASE_SQL_FAIL',
  'CONTACT_QUERY_FAIL',
  'SOMEONE_ELSE_FACTING_CONTACT',
  'YOURE_ALREADY_FACTING_CONTACT',
  'INSERT_CONTACT_FAIL',
  'STRIPE_CHARGE_ERROR',
  'UPDATE_USER_FAIL',
  'INVALID_PROMO_CODE',
  'USED_PROMO_CODE',
  'USED_TOO_MANY_CODES',
  'UPDATE_PROMO_CODE_FAIL'
];

export const errors = {
  name(code) {
    return ERROR_CODES[code]
  }
}

export const GENERAL_ERROR_MESSAGE = 'Something went wrong. Please try again.';

export const buildError = (code) => {
  switch (ERROR_CODES[code]) {
    case 'EMPTY_VALUE':
      return {
        message: 'Please make sure you\'ve filled out all fields.',
        selector: 'contact_phone_number'
      };
    case 'SOMEONE_ELSE_FACTING_CONTACT':
      return {
        message: 'Someone is already facting this target. Please choose another',
        selector: 'contact_phone_number'
      };
    case 'YOURE_ALREADY_FACTING_CONTACT':
      return {
        message: 'You\'re already facting this target. Please choose another',
        selector: 'name'
      };
    case 'STRIPE_CHARGE_ERROR':
      return {
        message: 'There was an error processing your credit card. Please try again',
        selector: '#card-errors'
      };
    case 'INVALID_PROMO_CODE':
    case 'UPDATE_PROMO_CODE_FAIL':
      return {
        message: 'This promo code is invalid / incorrect. Please try a different one.',
        selector: 'promo-code'
      };
    case 'USED_PROMO_CODE':
      return {
        message: 'This promo code has already been used.',
        selector: 'promo-code'
      };
    case 'USED_TOO_MANY_CODES':
      return {
        message: 'You\'ve hit the limit for promo codes.',
        selector: 'promo-code'
      };
    default:
      return GENERAL_ERROR_MESSAGE;
  };
};
