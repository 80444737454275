import SentryWrapper from "./sentry-wrapper";

const HAS_SEEN_REG_MODAL = "has_seen_reg_modal";

export default class UserFlags {
  setHasSeenRegModal() {
    try {
      localStorage.setItem(HAS_SEEN_REG_MODAL, true);
    } catch (e) {
      // Failed to write to localStorage, not sure what to do here.
      // This means the user has browser preferences that forbid us from using it
      SentryWrapper.captureException(e);
    }
  }

  hasSeenRegModal() {
    return localStorage.getItem(HAS_SEEN_REG_MODAL);
  }
}
