import "./checkout";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import SentryWrapper from "./sentry-wrapper";

// Autoload Stimulus controllers in the src/controllers directory
const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

SentryWrapper.init();
