// Create a Stripe client.
const stripe = Stripe(STRIPE_KEY);

// Create an instance of Elements.
const elements = stripe.elements()

// Create an instance of the card Element.
let cardElement = null

export const createCardElement = (disabled = false) => {
  cardElement = elements.create('card', { disabled })

  // Add an instance of the card Element into the `card-element` <div>.
  cardElement.mount('#card-element')

  // Handle real-time validation errors from the card Element.
  cardElement.addEventListener('change', ({error}) => {
    let displayError = document.getElementById('card-errors')

    if (error) {
      displayError.textContent = error.message;
    } else {
      displayError.textContent = '';
    }
  })

  return cardElement
}

export default class StripeClient {
  static async createToken() {
    return await stripe.createToken(cardElement)
  }
}
