import { Controller } from "stimulus";
import StripeClient, { createCardElement } from "../stripe";
import Checkout from "../checkout";
import CurrentUser from "../current_user";
import Analytics from "../analytics";
import { buildError, errors, GENERAL_ERROR_MESSAGE } from "../errors";
import { enforceFormat, formatToPhone } from "../phone_format";
import FactType from "../fact_type";
// https://github.com/jackocnr/intl-tel-input
import intlTelInput from "intl-tel-input";

import { showModal, hideModal } from "../reg-modal";
import SentryWrapper from "../sentry-wrapper";

const FREE_PLAN_ID = "tier_free_10";
const PROMO_PLAN_ID = "tier_promo_code";

const TIER_1_PRICE = "$1.99";
const TIER_2_PRICE = "$4.99";
const TIER_3_PRICE = "$9.99";

export default class CheckoutController extends Controller {
  static targets = [
    "name",
    "phone",
    "planForm",
    "frequencyForm",
    "typeForm",
    "promoCode",
    "paymentForm",
    "signupForm",
    "submitButton",
    "errorMessage",
  ];

  connect() {
    if (
      this.currentUser.isPersisted &&
      !document.referrer.includes("contacts")
    ) {
      this.navigateToContactsPage();
    }

    this.inputs.forEach((input) => {
      input.addEventListener("focus", () => {
        input.classList.remove("invalid");
      });
    });

    this.cardInput = createCardElement();

    this.setUiFromTypeQueryParam();

    this.updateUiWithPrices();

    if (this.currentUser.isEligibleForFreePlan) {
      this.showFreePlan();
      this.selectFreePlan();
    } else {
      this.planFormTarget
        .querySelector(".plan-item.free-tier")
        .classList.add("hide");
      this.enablePayment();
    }

    this.resetErrorStates();

    this.setPhoneInput();

    const loginButtonEl = document.getElementById("login-button");
    const emailButtonEl = document.getElementById("email-button");
    if (!this.currentUser.isLoggedIn) {
      emailButtonEl.style.display = "none";
      loginButtonEl.style.display = "inline-block";
      loginButtonEl.addEventListener("click", (event) => {
        showModal(false);
      });
    } else {
      loginButtonEl.style.display = "none";
      emailButtonEl.style.display = "inline-block";
      emailButtonEl.innerHTML = this.currentUser.emailAddress;
      emailButtonEl.addEventListener("click", (event) => {
        this.navigateToContactsPage();
      });
    }

    // Don't allow enter press - it breaks the form and promo codes
    document.addEventListener("keypress", function (e) {
      if (e.keyCode === 13 || e.which === 13) {
        e.preventDefault();
        return false;
      }
    });
  }

  navigateToContactsPage() {
    window.location.href = "/contacts.html";
  }

  /**
   * Updates the UI based on the constants at the top of this file.
   * Note: Eventually this can pull from the server (or come from server-side rendering)
   */
  updateUiWithPrices() {
    document.getElementById("tier-1-price").innerHTML = TIER_1_PRICE;
    document.getElementById("tier-2-price").innerHTML = TIER_2_PRICE;
    document.getElementById("tier-3-price").innerHTML = TIER_3_PRICE;
  }

  // ------------------------------------------------
  // Main Submit
  // ------------------------------------------------

  async onFormSubmit(event) {
    event.preventDefault();

    this.resetErrorStates();

    if (!this.validatePhoneInput()) {
      return;
    }

    if (!this.validate()) return;
    this.showLoading();
    this.disableButton();

    let submitData = this.formData;

    if (this.planId !== FREE_PLAN_ID && this.planId !== PROMO_PLAN_ID) {
      let { error, token } = await StripeClient.createToken();

      if (error) {
        Analytics.trackJsError(error, "stripe");

        this.enableButton();
        this.hideLoading();

        if (error.message) {
          this.cardErrorsElement.innerHTML = error.message;
        } else {
          this.showGeneralError();
        }
        return;
      }

      submitData.stripe_token = token.id;
    }

    Checkout.submit(submitData)
      .then((response) => {
        // repsonses with status < 400 get resolved (success!). you can access response.status and response.data here

        this.hideLoading();

        this.currentUser.save(response.data);

        try {
          Analytics.trackCompletedPurchase(submitData).then(() => {
            this.navigateToContactsPage();
          });
        } catch (error) {
          error.dev_message = "Failure to track completed purchase";
          Analytics.trackError(error);
          this.navigateToContactsPage();
        }
      })
      .catch((response) => {
        // repsonses with status >= 400 get rejected. you can access response.status and response.data here too
        if (
          response.data &&
          response.data.error_code !== null &&
          typeof response.data.error_code !== undefined
        ) {
          this.handleCheckoutError(response.data);
        } else {
          console.log(response.data);
          this.enableButton();
          this.hideLoading();
          this.showGeneralError();

          Analytics.trackJsError(response, "request");
        }
      });
  }

  // ------------------------------------------------
  // Error Handling
  // ------------------------------------------------

  handleCheckoutError(response) {
    const error = buildError(response.error_code);

    const cardErrorSelector = "#card-errors";
    const isCardError = error.selector == cardErrorSelector;
    const errorSeletor = isCardError
      ? cardErrorSelector
      : `#${error.selector}-errors`;
    let errorTextEl = this.element.querySelector(errorSeletor);

    Analytics.trackError(response);

    this.enableButton();
    this.hideLoading();

    if (!errorTextEl) {
      // If no error text element specifically, set the general one
      this.showGeneralError();
    } else if (isCardError) {
      // Card errors
      errorTextEl.innerHTML = error.message;
    } else {
      // All input errors (name, phone, promo)
      let inputEl = this.element.querySelector(`#${error.selector}-input`);
      errorTextEl.setAttribute("data-error", error.message);
      if (error.selector == "contact_phone_number") {
        // See comment in validatePhoneInput()
        // It'll get removed when they type a valid number
        errorTextEl.classList.add("force-show-input-error");
      }
      inputEl.classList.add("invalid");

      // * Changing the data-error property means that after re-typing in a number,
      // * if it's invalid (too short, for example), the error message will still be the server-error. That sucks.
      // ! This jank is because stripe card errors display in a <div>, not a material UI input
    }
  }

  resetErrorStates() {
    var elems = document.querySelectorAll(".invalid");

    [].forEach.call(elems, function (el) {
      el.classList.remove("invalid");
    });

    const nameErrorTextEl = this.element.querySelector("#name-errors");
    nameErrorTextEl.setAttribute("data-error", "Must provide a valid name");
    // const phoneNumberErrorTextEl = this.element.querySelector('#contact_phone_number-errors');
    // phoneNumberErrorTextEl.setAttribute('data-error', "Must provide a valid phone number");
    const promoCodeErrorTextEl =
      this.element.querySelector("#promo-code-errors");
    promoCodeErrorTextEl.setAttribute(
      "data-error",
      "Must provide a valid promo code"
    );
  }

  showGeneralError() {
    this.errorMessageTarget.innerHTML = GENERAL_ERROR_MESSAGE;
  }

  validate() {
    this.inputs.forEach((input) => {
      if (!input.checkValidity()) {
        input.classList.add("invalid");
      }
    });

    return this.signupFormTarget.checkValidity();
  }

  // ------------------------------------------------
  // Page State Management
  // ------------------------------------------------

  showLoading() {
    this.loader.classList.remove("hide");
  }

  hideLoading() {
    this.loader.classList.add("hide");
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  // ------------------------------------------------
  // Phone Number Input
  // ------------------------------------------------

  // Only ever access this field through the helpers below
  static phoneIti = null;

  isValidNumber() {
    return CheckoutController.phoneIti.isValidNumber();
  }

  getFormattedNumber() {
    return CheckoutController.phoneIti.getNumber(
      intlTelInputUtils.numberFormat.E164
    );
  }

  validatePhoneInput() {
    const inputElement = document.getElementById("contact_phone_number-input");
    const phoneNumberErrorTextEl = this.element.querySelector(
      "#contact_phone_number-errors"
    );

    if (this.isValidNumber()) {
      inputElement.classList.remove("invalid");
      // This is a hack, materialize css does this automatically when it sees "invalid" on the input
      // But because the phone number format lib changes the hierarchy, I need to do in manually
      phoneNumberErrorTextEl.classList.remove("force-show-input-error");

      console.log(this.contactPhone);
      return true;
    } else {
      inputElement.classList.add("invalid");

      phoneNumberErrorTextEl.setAttribute("data-error", "Invalid phone number");
      phoneNumberErrorTextEl.classList.add("force-show-input-error");
      return false;
    }
  }

  setPhoneInput() {
    const inputElement = document.getElementById("contact_phone_number-input");
    const iti = intlTelInput(inputElement, {
      // This lazy loads the Google library for formatting and such
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js",
      autoPlaceholder: "aggressive",
      separateDialCode: true,
      placeholderNumberType: "MOBILE",
      nationalMode: false,
      formatOnDisplay: true, // Needed for auto-format
      customPlaceholder: function (
        selectedCountryPlaceholder,
        selectedCountryData
      ) {
        if (selectedCountryData.iso2 == "us") {
          return "(860) 867-5309";
        }
        return selectedCountryPlaceholder;
      },

      // https://www.iban.com/country-codes
      preferredCountries: ["us", "ca"],
      onlyCountries: [
        "us",
        "ca",
        "in",
        "au",
        "de",
        "nl",
        "ie",
        "nz",
        "se",
        "no",
      ],
      // excludeCountries:
    });
    CheckoutController.phoneIti = iti;

    inputElement.addEventListener("countrychange", () => {
      iti.setNumber(iti.getNumber(intlTelInputUtils.numberFormat.NATIONAL));
      this.validatePhoneInput();

      try {
        const countryData = iti.getSelectedCountryData();
        const countryCode = countryData.iso2;
        Analytics.track("Select country", countryCode);
      } catch (e) {}
    });

    // https://github.com/jackocnr/intl-tel-input/issues/367
    // There's also an issue in intl-tel-input where it talks about removing autoformat
    function formatIntlTelInput() {
      if (typeof intlTelInputUtils !== "undefined") {
        // utils are lazy loaded, so must check
        var currentText = iti.getNumber(
          intlTelInputUtils.numberFormat.NATIONAL
        );
        if (typeof currentText === "string") {
          // sometimes the currentText is an object :)
          iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
        }
      }
    }

    const formatToPhoneWrapper = (event) => {
      this.validatePhoneInput();
      var countryData = iti.getSelectedCountryData();
      if (countryData.iso2 == "us") {
        formatToPhone(event);
      } else {
        formatIntlTelInput();
      }
    };

    const enforceFormatWrapper = (event) => {
      var countryData = iti.getSelectedCountryData();
      if (countryData.iso2 == "us") {
        enforceFormat(event);
      } else {
        formatIntlTelInput();
      }
    };

    inputElement.addEventListener("keydown", enforceFormatWrapper);
    inputElement.addEventListener("keyup", formatToPhoneWrapper);
  }

  // ------------------------------------------------
  // Promo / Plan Type
  // ------------------------------------------------

  onPlanSelect(event) {
    // NOTE: This is necessary because the browser triggers two events if the user clicks on
    // the label of a radio button. One for the label click, and a synthetic one for the input.
    if (event.target.nodeName !== "INPUT") {
      return;
    }

    const planCard = event.target.closest("label");
    this.selectPlanAndUpdatePageState(planCard);
  }

  selectPlanAndUpdatePageState(planCard) {
    this.planCards.forEach((card) => {
      card.classList.remove("selected");
    });

    planCard.classList.add("selected");

    if (planCard.classList.contains("free-tier")) {
      this.showFreePaymentOption();
    } else if (planCard.classList.contains("promo-tier")) {
      this.showPromoEntry();
    } else {
      this.enablePayment();
    }
  }

  showAndSelectPromoPlan() {
    this.showPromoPlan();
    // Selecting promo plan will indirectly showPromoEntry
    this.selectPromoPlan();
  }

  showPromoEntry() {
    this.promoCodeEntryElement.classList.remove("hide");
    this.paymentFormTarget.querySelector("#promo-code-input").required = true;
    this.disabledPaymentElement.classList.add("hide");
    this.cardErrorsElement.classList.add("hide");
    this.cardInput.unmount();
  }

  // Selecting tier 2 will trigger the payment form to show
  hidePromoEntry() {
    this.promoPlan.classList.add("hide");
    this.planTier2.querySelector('input[type="radio"]').checked = true;
    this.selectPlanAndUpdatePageState(this.planTier2);
  }

  showPromoPlan() {
    this.planFormTarget
      .querySelectorAll(".selected:not(.promo-tier)")
      .forEach((el) => el.classList.remove("selected"));

    this.promoPlan.classList.remove("hide");
    this.promoPlan.classList.add("scale-in");
  }

  selectPromoPlan() {
    this.promoPlan.querySelector('input[type="radio"]').checked = true;
    this.selectPlanAndUpdatePageState(this.promoPlan);
  }

  showFreePlan() {
    // Hide tier 1 since it's replaced with "free"
    this.planFormTarget.querySelector("#tier-1").classList.add("hide");

    this.freePlan.classList.remove("hide");
    this.freePlan.classList.add("scale-in");
  }

  selectFreePlan() {
    this.freePlan.querySelector('input[type="radio"]').checked = true;

    this.selectPlanAndUpdatePageState(this.freePlan);
  }

  enablePayment() {
    this.disabledPaymentElement.classList.add("hide");
    this.promoCodeEntryElement.classList.add("hide");
    this.paymentFormTarget.querySelector("#promo-code-input").required = false;
    this.cardErrorsElement.classList.remove("hide");
    this.cardInput.mount(this.cardElement);
  }

  showFreePaymentOption() {
    this.disabledPaymentElement.classList.remove("hide");
    this.promoCodeEntryElement.classList.add("hide");
    this.paymentFormTarget.querySelector("#promo-code-input").required = false;
    this.cardErrorsElement.classList.add("hide");
    this.cardInput.unmount();
  }

  // ------------------------------------------------
  // Fact Type
  // ------------------------------------------------

  onSelectFrequency(e) {
    Analytics.track("Select Frequency", e.target.value);
  }

  onSelectType(e) {
    Analytics.track("Select Type", e.target.value);
    this.setQueryParamAndUpdateUiForType();
  }

  setQueryParamAndUpdateUiForType() {
    if (history.replaceState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?factType=" +
        this.typeFormTarget.value;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
    this.setUiFromTypeQueryParam();
  }

  setUiFromTypeQueryParam() {
    // Extract the fact type from the "factType" query param
    let urlParams = new URLSearchParams(window.location.search);
    var factTypeFromQueryParam = urlParams.get("factType");
    // Default to 0 which is cat
    factTypeFromQueryParam =
      factTypeFromQueryParam == undefined ? 0 : factTypeFromQueryParam;
    let factTypeString = FactType.intToFactType(factTypeFromQueryParam);

    // Set the dropdown to be the type
    this.typeFormTarget.value = factTypeFromQueryParam;

    // Update the header text
    const typeHeader = this.element.querySelector("#type-header");
    if (typeHeader) {
      typeHeader.innerHTML = factTypeString;
    }

    // Update the header image
    const typeHeaderImage = this.element.querySelector(".header-image");
    this.removeClassByPrefix(typeHeaderImage, "logo-");
    typeHeaderImage.classList.add("logo-" + factTypeString);

    if (factTypeFromQueryParam == 5) {
      this.changeBrandColor("red");
    } else if (factTypeFromQueryParam == 6) {
      this.changeBrandColor("blue");
    } else {
      this.changeBrandColor("green");
    }
  }

  changeBrandColor(color) {
    this.replaceClass(
      "box-selected-brand-color-",
      "box-selected-brand-color-" + color
    );
    this.replaceClass(
      "background-brand-color-",
      "background-brand-color-" + color
    );
    this.replaceClass("input-brand-color-", "input-brand-color-" + color);
  }

  replaceClass(oldClassSubstring, newClassName) {
    const els = document.querySelectorAll(
      '[class*="' + oldClassSubstring + '"]'
    );
    els.forEach((el) => {
      // Remove the old class
      this.removeClassByPrefix(el, oldClassSubstring);
      el.classList.add(newClassName);
    });
  }

  removeClassByPrefix(el, prefix) {
    // Spread the classList to cast to array to filter
    const classesToRemove = [...el.classList].filter((className) =>
      className.includes(prefix)
    );
    classesToRemove.forEach((className) => el.classList.remove(className));
  }

  // ------------------------------------------------
  // Getters
  // ------------------------------------------------

  get formData() {
    return {
      contact_name: this.contactName,
      contact_number: this.contactPhone,
      contact_tier: this.planId,
      contact_interval: this.contactFrequency,
      contact_fact_type: this.contactFactType,
      contact_promo_code: this.contactPromoCode,
    };
  }

  get planCards() {
    return this.planFormTarget.querySelectorAll("label");
  }

  get promoPlan() {
    return this.planFormTarget.querySelector(".plan-item.promo-tier");
  }

  get freePlan() {
    return this.planFormTarget.querySelector(".plan-item.free-tier");
  }

  get planTier2() {
    return this.planFormTarget.querySelector("#tier-2-plan");
  }

  get promoCodeEntryElement() {
    return this.paymentFormTarget.querySelector(".promo-code-entry");
  }

  get disabledPaymentElement() {
    return this.paymentFormTarget.querySelector(".disabled-payment");
  }

  get contactPromoCode() {
    return this.promoCodeTarget.value;
  }

  get contactFactType() {
    let factTypeValue = this.typeFormTarget.value;
    return factTypeValue == undefined ? "0" : factTypeValue;
  }

  get contactFrequency() {
    return this.frequencyFormTarget.value;
  }

  get contactName() {
    return this.nameTarget.value;
  }

  get strippedPhoneNumber() {
    let number = this.getFormattedNumber();

    // Removes the leading + which the phone number lib puts on it
    return number.replace(/\D/g, "");
  }

  get contactPhone() {
    return this.strippedPhoneNumber;
  }

  get planId() {
    return this.planFormTarget.querySelector('input[type="radio"]:checked')
      .value;
  }

  get inputs() {
    return this.element.querySelectorAll("input.validate");
  }

  get cardElement() {
    return this.paymentFormTarget.querySelector("#card-element");
  }

  get cardErrorsElement() {
    return this.paymentFormTarget.querySelector("#card-errors");
  }

  get loader() {
    return document.querySelector("#loader");
  }

  get currentUser() {
    return new CurrentUser();
  }
}
